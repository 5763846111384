// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCUSg9EFV3BNbFUbywNpa6YlP9p9k4j8gA",
    authDomain: "ziil-taxi.firebaseapp.com",
    projectId: "ziil-taxi",
    storageBucket: "ziil-taxi.appspot.com",
    messagingSenderId: "567579728052",
    appId: "1:567579728052:web:ceefb4877e623591ab1fe1",
    measurementId: "G-YVHM3V0LQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export default app