import { Link, NavLink } from "react-router-dom";
import "../style/NavbarS/NavBar.css";
import MenuPhone from "../components/MenuPhone";
import PersonIcon from '@mui/icons-material/Person';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

function NavBar() {
  return (
    <header className="header">
      <div className="navbar_x">
        <div >
          <MenuPhone />
        </div>
        <div className="BarBox"><NavLink to="./" activeClassName="active_link">ZIIL-TAXI </NavLink></div>
        <div className="navBar">
       
        <NavLink to="./" className="BarBox" activeClassName="active_link">
          HOME
        </NavLink>

        <NavLink to="./Kontakt" className="BarBox" activeClassName="active_link">
          KONTAKT
        </NavLink>
      </div>
      <div className="icon_menu_bar">
      
      {/* <div> <NavLink to="./Login" className="BarBox2">Anmeden</NavLink> /<NavLink to="./Register" className="BarBox2"> Registrieren</NavLink></div> */}

      <div className="BarBox"><LocalPhoneIcon  className="nummer1"/><a className="nummer1" href="tel:+41794001111" alt="Handy Nummero">079 400 11 11</a></div>
      </div>
      </div>
    </header>
  );
}

export default NavBar;
