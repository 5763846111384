import { createUserWithEmailAndPassword } from 'firebase/auth';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {collection, addDoc, onSnapshot, doc, setDoc} from "firebase/firestore";
import { auth } from '../../firebase-config';
import { UserAuth } from '../data/Context';
import { db } from '../../firebase-config';
import  "../../style/Login.css";
import Footer from '../../pages/Footer';

const Register = () => {
    
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate()
  const { createUser } = UserAuth();

  const { user } = UserAuth();

  const [userid, setUserId] = useState('');

  const [currentUser, setCurrentUser] = useState();
  const [data, setData] = useState('');

  //Data User
  const [mobile, setMobile] = useState("");
  const [vorname, setVorname] = useState("");
  const [nachname, setNachname] = useState("");


// react firestore, get uid and add in collection?

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const res = await createUser(email, password);
      navigate('/Profile')
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        vorname,
        nachname,
        mobile,
        email

      });
    } 

    catch (e) {
      setError(e.message);
      console.log(e.message);
    }



  };

  return (
    <div>
      <div className="container1">
      <h1>Konstenlos Anmeldung</h1>
      <form className="form1" onSubmit={handleSubmit}>
<div>
      <label >Vorname</label>
          <input
            onChange={(e) => setVorname(e.target.value)}
            type='phone'
          />

<label >Nachname</label>
          <input
            onChange={(e) => setNachname(e.target.value)}
            type='phone'
          />

</div>
        
          <label >Email Address</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type='email'
          />

          <label >Mobile</label>
          <input
            onChange={(e) => setMobile(e.target.value)}
            type='phone'
          />
       
      
          <label >Password</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            />
            <button>
            Einloggen
            </button>
      
      </form>
      
        <p>
          Besitzen Sie schon eine Account?{' '}
          <Link to='/Login'>
           <span className="link">Accedi.</span>
          </Link>
        </p>
      </div>
      <Footer/>
    </div>
  );
};

export default Register;