import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
} from 'firebase/auth';

import { auth } from '../../firebase-config';
import  "../../style/Login.css";
import { UserAuth } from '../data/Context';
import Footer from '../../pages/Footer';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        try {
          await signIn(email, password)
          navigate('/Profile')
        } catch (e) {
          setError(e.message)
          console.log(e.message)
        }
      };

  return (
    <div>
        <div className="container1">
            <form className="form1" onSubmit={handleSubmit}>
            <label>Login</label>
            <input  type="email" onChange={(e) => setEmail(e.target.value)}></input>
            <label  >Password</label>

            <input type="password" onChange={(e) => setPassword(e.target.value)}></input>
            <button >Collegati</button>
            <Link to='/Logup'>Non hai un account?<span className="link">Registrati</span></Link>
            </form>
        </div>

        <Footer/>
    </div>
  )
}

export default Login