import * as React from "react";
import {useState} from 'react';
import { Link, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import "../style/NavbarS/NavBar.css";


export default function BasicMenu() {

  const [isActive, setIsActive] = useState(true);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(isActive);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsActive(current => !current);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsActive("false");
  };

  return (
    <div className="displaynone">
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon className="iconMenuHamburg_"/>
      </Button>
      <div
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
       
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}


      >
        <div  className="MenuHambDiv_"
         style={{
          display: isActive ? 'none' : 'flex',
        }}
        >
        <div>
        <MenuItem onClick={handleClose}>
          <NavLink to="/" className="menuPhone" activeClassName="selected2">HOME</NavLink>
        </MenuItem>
       
       
        <MenuItem onClick={handleClose}>
          <NavLink to="/Kontakt" className="menuPhone" activeClassName="selected2">KONTAKT</NavLink>
        </MenuItem>
        <div className="contact_h">
          <p>Kontakt</p>
          <div>
          <a href="tel:+41794001111">Tel: 079 400 11 11</a>
          <a href="mailto:info@ziil-taxi">info@ziil-taxi</a>
          </div>
        </div>
        </div>
        <div className="close_div" onClick={handleClose}>
          X</div>
        </div>
      </div>

    </div>
  );
}
