import "../style/HomeS/Home.css";
import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import Banner from "./Banner";
import Footer from "./Footer";
import styles from '../style/header/Home.module.css';
import { Link } from "react-router-dom";
import TabsCar from "../components/TabsCar";

function Home2() {
    const imgs = [
        {
            id: 0,
            value: "Chat-ziil-taxi-flughafen.webp",
            title: "SMS",
            des: "Durch die Verwendung von SMS kann der Standort des Kunden genau ermittelt werden, was zu einer schnelleren Ankunft des Taxis führt. Die Fahrer können auch den genauen Ort des Kunden erreichen, selbst wenn sie sich in einer unbekannten Gegend befinden.",
            link: "",
            message: "Nachricht Senden"
        },

        {
            id: 1,
            value: "whatsapp_chat.png",
            title: "WHATSAPP",
            des: "Die Elefanten tanzen fröhlich auf den Wolken, während die Kätzchen im Regen spielen.",
            link: "",
            message: "Nachricht Senden"
        },

        {
            id: 2,
            value: "white",
            title: "TELEFON",
            des: "Das flauschige Einhorn reitet auf einem Regenbogen, während die Roboter im Hintergrund tanzen",
            link: "",
            message: "Jetzt Anrufen"
        },

    ]

    const autosSelect = [
        {
            id: 0,
            value: "Chat-ziil-taxi-flughafen.webp",
            title: "Toyota prius",
            des: "Bis 4 personen, Klein wagen",
            link: "",
            message: "Nachricht Senden"
        },

        {
            id: 1,
            value: "whatsapp_chat.png",
            title: "Toyota prius Plus",
            des: "Bis 4 personen, midium wagen bit grösser gepäck",
            link: "",
            message: "Nachricht Senden"
        },

        {
            id: 2,
            value: "white",
            title: "Mercedes Vito",
            des: "bis 7 personen, Big gepäck",
            link: "",
            message: "Jetzt Anrufen"
        },
    ]

    const [wordData, setWordData] = useState(imgs[0])
    const handleClick = (index) => {
        console.log(index)
        const wordSlider = imgs[index];
        setWordData(wordSlider)
    }

    return (


        <div className={styles.page}>

            <Helmet>
                <meta charSet="utf-8" />
                <title>Home | Ziil-Taxi Kreuzlingen</title>

                <meta property="og:title" content="ZIIL-TAXI: Jetzt dein taxi bestellen" />
                <meta property="og:description" content="Der einfachste Taxi-Unternehmer aus Kreuzlingen. Wir sind bekannt von unserer Freundlichkeit und vertrauen voll.
                Flughafen, Kurier, Transport bis 6 Personen, Zahlung Art: Bar, Visa, MasterCard, Visa, TWINT." />

                <meta name="description" content="Der einfachste Taxi-Unternehmer aus Kreuzlingen. Wir sind bekannt von unserer Freundlichkeit und vertrauen voll.
                Flughafen, Kurier, Transport bis 6 Personen, Zahlung Art: Bar, Visa, MasterCard, Visa, TWINT."/>
                <meta name="keywords" content="Flughafen, Preis, Kreuzlingen, Zürich, Konstanz, Taxi, Kurier" />
                <link rel="canonical" href="https://www.ziil-taxi.ch/" />
            </Helmet>

            {/* Testo */}


            <Banner />
            <section className={styles.colum}>
                <div className={styles.cards}>

                    <div className={styles.cards_flex}>
                        <div className={styles.border_b}>
                            <img src="taxi.png" height="150px" />
                        </div>

                        <div className={styles.desc_cards}>
                            <h2>TAXI</h2>
                            <h4>Schnelle und sichere Taxifahrten in Kreuzlingen und Umgebung</h4>
                            <p>Wenn Sie in Kreuzlingen und Umgebung unterwegs sind,
                                ist unser Taxi-Unternehmen die beste Wahl für eine schnelle,
                                bequeme und günstige Fahrt. Unsere erfahrenen und freundlichen
                                Fahrer kennen die Gegend wie ihre Westentasche und bringen Sie
                                schnell und sicher zu Ihrem Ziel.
                            </p>
                            {/* <a href="/Taxiinfos" className={styles.link}>Weiter infos</a> oder <a className={styles.link} href="/Taxiinfos#section-faq">Weiter zu F.A.Q</a> */}

                        </div>
                    </div>

                    <div className={styles.cards_flex}>
                        <div className={styles.border_b}>

                            <img src="kurier.png" height="150px" />
                        </div>

                        <div className={styles.desc_cards}>
                            <h2>KURIER</h2>
                            <h4>Schneller und zuverlässiger Kurierdienst für Medikamente und kleine Waren</h4>
                            <p>
                                Unser Taxi-Unternehmen bietet einen zuverlässigen Kurierdienst
                                für Medikamente und kleine Waren bis zu 30 kg. Wir verstehen,
                                wie wichtig es ist, dass diese Produkte schnell und sicher transportiert werden,
                                und wir bieten unseren Kunden einen Service, auf den sie sich verlassen können.
                            </p>
                            {/*<a href="/Kurierinfos" className={styles.link}>Weiter infos</a > oder <a className={styles.link} href="/Kurierinfos#section-faq">Weiter zu F.A.Q</a>  */}

                        </div>
                    </div>

                    <div className={styles.cards_flex}>
                        <div className={styles.border_b}>

                            <img src="iconflug.png" height="150px" />
                        </div>

                        <div className={styles.desc_cards}>
                            <h2>FLUGHAFEN</h2>
                            <h4>Günstiger und zuverlässiger Flughafentransfer von Kreuzlingen</h4>
                            <p>
                                Unser Taxi-Unternehmen bietet Ihnen einen günstigen
                                und zuverlässigen Flughafentransfer von Kreuzlingen
                                und Umgebung zu allen wichtigen Flughäfen in der Region,
                                einschließlich des Flughafens Zürich.
                            </p>
                            {/* <a href="/Flughafeninfos" className={styles.link}>Weiter infos</a> oder <a className={styles.link} href="/Flughafeninfos#section-faq">Weiter zu F.A.Q</a>  */}

                        </div>
                    </div>
                </div>


            </section>
            {/*
                                  <section>
                                    <div className={styles.phone_book}>
                                    <h4>EINFACH <br/>BESTELLEN <br/> MIT EINEM TOUCH</h4>
                                    <div className={styles.phone_}>
                                    <img src={wordData.value} className={styles.background_phone_} />
                                    <img src="apple-iphone-14-pro-max-2022-medium.png" className={styles.img_iphone}/>
                                    </div>

                                    <div className={styles.text_phone}>
                                        <div className={styles.text_style_p_1}>
                                        <h2 >{wordData.title}</h2>
                                        <p>
                                        {wordData.des}
                                            
                                        </p>
                                        <a href={wordData.link}>{wordData.message}</a>
                                        </div>
                                        {imgs.map((data,i)=>
                                        <div key={i} className={styles.text_style_p_2}>
                                        <h2 className={wordData.id==i?styles.clicked:""} src={data.value} onClick={()=>handleClick(i)}>{data.title}</h2>
                                        <p>
                                        {data.des}
                                            
                                        </p>
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                  </section>
*/ }

            {/*
                                  <section>
                                    <div className={styles.automain}>
                                    <div className={styles.autos_card}>
                                        
                                        <TabsCar/>
                                    </div>
                                    <h2>UNSERE AUTO <br/>IHRE WAHL</h2>
                                    </div>
                                    
                                  </section>
                                   */ }


            <Footer />
        </div>
    );
}

export default Home2;