import DataPicker from "../components/DataPicker";
import React, { useRef, useState, useEffect, Component } from 'react';
import emailjs from '@emailjs/browser';
import styles from '../style/header/Kontakt.module.css';


const FormKontakt = () => {

  function UserGreeting(props) {
    return <div>Email würde versendet</div>;
  }
  
  function GuestGreeting(props) {
    return <div>Email würde nicht versendet, ERROR</div>;
  }

  const form = useRef();
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1oxdvoc', 'template_oajqvtc', form.current, 'i_U9_Bw4a-U8yNi_G')
        .then((result) => {
          alert("Ihre Anfrage würde versendet, Sie erhalten in Kürze eine Bestätigung von Ihrer Buchung")
        }, (error) => {
          alert("Leider ist etwas schif gelaufen, bitte kontaktieren sie uns über telefon oder versuchen Sie es noch mal")
        });
    };

  return (
    <section className={styles.form}>
    <form className={styles.form} ref={form} onSubmit={sendEmail}>
         
    <h2>Kontaktformular</h2>
    
    <div> 
    <div className={styles.grid}>
    <div className={styles.label_input}>
    <div className={styles.grid}>
    <label for="fname" >Vorname:* </label>
    <input type="text" name="user_vorname"   required></input>
    </div>
    <div className={styles.grid}>
    <label for="fname" >Nachname:*</label>
    <input type="text" name="user_nachname"   required></input>
    </div>
    
    </div>
    <div className={styles.label_input}>
    <div className={styles.grid}>
    <label for="fname" >E-Mail:*</label>
    <input type="text" name="user_email"  required ></input>
    </div>
    <div className={styles.grid}>
    <label for="fname"> Mobile:*</label>
    <input type="number" id="fname" name="user_mobile" placeholder="" required></input>
    </div>
    </div>
    </div>
    </div>


    <div className={styles.grid}>  
    <label for="fname" className="label">Nachricht:</label>
    <textarea name="message"></textarea>
    </div>
    <input className={styles.button_form} type="submit" value="Senden"/>
    </form>
    <div className={styles.alternative_contact}>
      <p>Oder <br/> per Telefon</p>
      <a href="tel:+41794001111">079 400 11 11</a>
    </div>
    </section>
  )
}

export default FormKontakt