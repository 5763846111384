import React from 'react'
import Footer from './Footer'
import styles from "../style/module/Prius.module.css"

function Prius() {
  return (
    <div className={styles.mainDiv}>
     
        <div className={styles.container}>
        <div className={styles.text}>
            <h3>Toyota Prius Plus <br/></h3>
            <p>bis 6 Plätze</p>
        </div>
        <div className={styles.img}>
        <img
        alt='toyota prius seite bild'
       className={styles.img_toCorrect} 
       src="./toyota2.jpg"/>
        </div>
        <div className={styles.img}>
        <img
       alt='toyota prius seite bild'
       className={styles.img_toCorrect} 
       src="./toyota.jpg"/>
        </div>
        </div>

        <Footer/>
    </div>
  )
}

export default Prius