import Footer from "./Footer";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import stylese from '../style/header/FormFlug.module.css';

const FormTaxi = () =>  {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_1oxdvoc', 'template_oajqvtc', form.current, 'i_U9_Bw4a-U8yNi_G')
      .then((result) => {
        alert("Ihre Anfrage würde versendet, Sie erhalten in Kürze eine Bestätigung von Ihrer Buchung")
      }, (error) => {
        alert("Leider ist etwas schif gelaufen, bitte kontaktieren sie uns über telefon oder versuchen Sie es noch mal")
      });
    };
  return (
    <div>
    <div className={stylese.maindiv_}>
    <form className="form" ref={form} onSubmit={sendEmail}>
         
    <h1>TAXI BOOKING</h1>
  
    <div className="divForm"> 
    <div className="label_input">
    <label for="fname" className="label">Vorname* </label>
    <input type="text" name="user_vorname" className="input" required></input>
    <label for="fname" className="label">Nachname*</label>
    <input type="text" name="user_nachname"  className="input" required></input>
    <label for="fname" className="label">E-Mail*</label>
    <input type="text" name="user_email"  className="input" required></input>
    <label for="fname" className="label">Mobile: *</label>
    <input type="number" id="fname" name="user_mobile" placeholder="" className="input" required></input>
    <label for="fname" className="label">Von:*</label>
    <input type="text" name="user_vonAbholung"  className="input" required></input>
    <label for="fname" className="label">Zu:*</label>
    <input type="text" name="user_zuAbholung" className="input" required></input>
    <label for="fname" className="label">Abholung Zeit:*</label>
    <div className="uhr_div">
    <input type="number" id="fname" name="user_abholungZeit" placeholder="13" className="inputZeit" required></input>
    <div>:</div>
    <input type="number" id="fname" name="user_abholungZeit2" placeholder="00" className="inputZeit" required></input>
    </div>

    <label for="fname" className="label">Termin um:*</label>
    <div className="uhr_div">
    <input type="number" id="fname" name="user_TerminZeit" placeholder="15" className="inputZeit" required></input>
    <div>:</div>
    <input type="number" id="fname" name="user_TerminZeitZeit2" placeholder="00" className="inputZeit" required></input>
    </div>
    <label for="fname" className="label" required>Personen:*</label>
    <select id="country" name="user_personen" className="input" required>
            <option value="">Bitte eins Wählen</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7 max</option>
            
    </select>
    </div>
    <div className="label_input">  
    <label for="fname" className="label">Nachricht</label>
    <input type="text" id="fname" name="message" placeholder="" className="inputMessage" value=""></input>
    </div>
    <button className="btn_submit" type="submit" value="Send">Senden</button>
    </div>
    </form>
    </div>
    <Footer/>
    </div>
  )
}

export default FormTaxi