import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../data/Context';

const NavigateRouteProtected = ({ children }) => {
  const { user } = UserAuth();

  if (!user) {
    return <Navigate to='/' />;
  }
  return children;
};

export default NavigateRouteProtected;