import React, { useState, useEffect } from 'react'
import {collection, getDocs, doc, getDoc} from "firebase/firestore";
import { db } from '../../firebase-config';
import Footer from '../../pages/Footer'
import { UserAuth } from '../data/Context';
import "../../style/Profile.css"

function Profile() {

  const { user } = UserAuth();
  const [users, setUsers] = useState([]);

  const usersCollectionRef = collection(db, "users", user.uid);

  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      setUsers(data.docs.map((doc) => ({...doc.data(), id: doc.id})));
    }

    getUsers()
  }, [])




  return (
    <div>
        <div className="container_prof">
          Profile
                      
                        <div>
                         <div>
                            Menu
                         </div>

                         <div>
          
                          <p>Daten</p>
                       {users.map((Esercizi) => {
                        return ( 
                          <div>test</div>
                          );  
                        })}
                          </div>
                          </div>  
          </div>
        <Footer/>
    </div>
  )
}

export default Profile