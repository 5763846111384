
import Footer from "./Footer";
import { Helmet } from 'react-helmet'
import "../style/KontaktS/Kontakt.css";
import styles from '../style/header/Kontakt.module.css';
import FormFlughafen from "../components/FormFlughafen";
import FormKontakt from "../components/FormKontakt";

function Kontakt() {
  return (
    <div>
       <Helmet>
                <meta charSet="utf-8" />
                <title>Kontakt | Ziil-Taxi Kreuzlingen</title>
                <meta name="description" content="Kontakt mit Ziil-Taxi aufnehmen für weitere Info zu erhalten."/>
                <meta name="keywords" content="info, booking, job" />
                <link rel="canonical" href="https://www.ziil-taxi.ch/Kontakt" />
            </Helmet>
       
      <div className={styles.main_div}>
        <section className={styles.section}> 
        <div>
        <div className={styles.img_cover}>                                    
        <img src="botschaft.png"/>
        </div>
        </div>
        <div>
        <h1 className={styles.title}>KONTAKT</h1>
        <p>
        Benötigen Sie ein Taxi oder haben Sie Fragen? Wir helfen Ihnen gerne weiter! Füllen Sie einfach unser Kontaktformular aus und wir werden uns schnellstmöglich bei Ihnen melden. Wir freuen uns auf Ihre Nachricht und stehen Ihnen jederzeit zur Seite.</p>
        </div>
        </section>
        <section className={styles.form_section}>
        <FormKontakt/>

        </section>
        <section className={styles.color_back}>

        <div className={styles.phone_contact}>
    
        <div className={styles.text_align}>
          <div className={styles.card}>
          <h3>ÖFFNUNGSZEITEN</h3>
          <p>
            Montag bis Sonntag <br/> Vormittag 08:00 – 12:00 Uhr<br/> Nachmittag 13:30 –
            3:00 Uhr  <br/>Freitagnachmittag bis 3:00 Uhr
          </p>
          </div>
        </div>


        <div className={styles.text_align}>
        <div className={styles.card}>
          <h3>BÜRO</h3>
          <p>
            ZIIL TAXI 
            <br/> Schützenstr. 25
            <br/> 8280 Kreuzlingen
          </p>
          </div>
        </div>

        <div className={styles.text_align}>
        <div className={styles.card}>
          <h3>KONTAKT</h3>
          <p>
            Tel: 079 400 11 11 <br/> 
          </p>
          <p>
            Mail: info@ziil-taxi.ch <br/> 
          </p>
          </div>
        </div>
        
    
        </div>
        </section>
      </div>
     <Footer/>
      
    
    </div>
  );
}

export default Kontakt;
