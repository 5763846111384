import React from 'react'
import styles from "../../style/header/Infos.module.css"

function TaxiInfos() {
  return (
    <div>
        <section className={styles.section}>
            <img src=""/>
            <h1>TAXI</h1>
            <p>
                Unsere Taxi-Unternehmer sind für Sie da, 
                um Sie von jedem Ort innerhalb 
                eines 20-Kilometer-Radius 
                von Kreuzlingen abzuholen. Bitte beachten Sie jedoch, 
                dass für Fahrten über 5 Kilometer 
                hinaus eine zusätzliche 
                Gebühr anfallen kann. Dies ist aufgrund der 
                längeren Entfernung 
                und des zusätzlichen Zeitaufwands für den Fahrer und das Fahrzeug notwendig.
                Wir möchten sicherstellen, dass wir unseren Kunden stets einen zuverlässigen und qualitativ 
                hochwertigen Service bieten, der ihren Anforderungen entspricht. Wir sind uns bewusst, 
                dass Mobilität für Menschen mit Behinderungen besonders wichtig ist, und wir sind stolz darauf, 
                ein barrierefreies Taxi-Service anzubieten, das für alle zugänglich ist. 
                Unsere freundlichen und erfahrenen Fahrer 
                stehen Ihnen jederzeit zur Verfügung, 
                um Sie sicher und komfortabel an Ihr Ziel zu bringen
            </p>

            <h2>TEAM</h2>
<div className={styles.flex}>
            <div className={styles.card}>
                <img src=""/>
            </div>

            <div className={styles.card}>
                <img src=""/>
            </div>

            <div className={styles.card}>
                <img src=""/>
            </div>
</div>

        </section>
        <section className={styles.section}>
            <h2 id="section-faq">F.A.Q</h2>
        </section>
    </div>
  )
}

export default TaxiInfos