import React, { useState, useEffect } from 'react'
import { auth } from './firebase-config';

import Helmet from "react-helmet";
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar";
import NavBarUser from "./scenes/user/NavBarUser";
import AutoMieten from "./pages/AutoMieten";
import ZahlungArt from "./pages/ZahlungArt";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import Kontakt from "./pages/Kontakt";
import Booking from "./pages/Booking";
import FormTaxi from "./pages/FormTaxi";
import FormKurier from "./components/FormKurier";
import FormKontakt from "./components/FormKontakt";
import FormFlughafen from "./components/FormFlughafen";

import { AuthContextProvider } from './scenes/data/Context';
import ProtectedRoute from './scenes/global/ProtectedRoute';
import ProtectedRouteItem from './scenes/global/ProtectedRouteItem';

import Profile from './scenes/user/Profile';
import Ride from './scenes/user/Ride';
import Login from './scenes/user/Login';
import Register from './scenes/user/Register';
import NavigateRouteProtected from './scenes/global/NavigateRouteProtected';
import Prius from './pages/Prius';
import Home2 from './pages/Home2';
import TaxiInfos from './pages/infos/TaxiInfos';
import KurierInfos from './pages/infos/KurierInfos';
import FlughafenInfos from './pages/infos/FlughafenInfos';


function App() {

      const [user, setUser] = useState(null)
      useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(userAuth => {
          const user = {
            uid: userAuth?.uid,
            email: userAuth?.email
          }
          if (userAuth) {
            console.log(userAuth)
            setUser(user)
          } else {
            setUser(null)
          }
        })
        return unsubscribe
      }, [])


  return (
    <div>
      <div>
        
  
        <Helmet>
          <title>ZIIL-TAXI</title>
          <meta name="description" content="Der einfachste Taxi-Unternehmer aus Kreuzlingen. Wir sind bekannt von unserer Freundlichkeit und vertrauen voll.
Flughafen, Kurier, Transport bis 6 Personen, Zahlung Art: Bar, Visa, MasterCard, Visa, TWINT."/>
<meta property="og:title" content="San Roque 2014 Pollos" />
<meta property="og:description" content="Programa de fiestas" />
<meta property="og:image" content="http://pollosweb.wesped.es/programa_pollos/play.png" /> 
        </Helmet>


      <NavBar />
      
            <Routes >
            <Route exact path="/" activeClassName="selected" element={<Home2/>} />
      </Routes >


            <Routes >
            <Route exact path="/AutoMieten" activeClassName="selected" element={<AutoMieten/>} />
      </Routes >

            <Routes >
            <Route exact path="/Impressum" activeClassName="selected" element={<Impressum/>} />
      </Routes >


            <Routes >
            <Route exact path="/ZahlungArt" activeClassName="selected" element={<ZahlungArt/>} />
      </Routes >


      <Routes >
            <Route exact path="/Kontakt" activeClassName="selected" element={<Kontakt/>} />
      </Routes >

      <Routes >
            <Route exact path="/Booking" activeClassName="selected" element={<Booking/>} />
      </Routes >

      <Routes >
            <Route exact path="/Booking/Classic" activeClassName="selected" element={<FormTaxi/>} />
      </Routes >
      <Routes >
            <Route exact path="/Booking/FlughafenBooking" activeClassName="selected" element={<FormFlughafen/>} />
      </Routes >
      <Routes >
            <Route exact path="/Booking/KurierBooking" activeClassName="selected" element={<FormKurier/>} />
      </Routes >

      <Routes >
            <Route exact path="/Login" activeClassName="selected" element={<Login/>} />
      </Routes >

      <Routes >
            <Route exact path="/Register" activeClassName="selected" element={<Register/>} />
      </Routes >

      <Routes >
            <Route exact path="/Profile" activeClassName="selected" element={<NavigateRouteProtected><Profile/></NavigateRouteProtected>} />
      </Routes >

      <Routes >
            <Route exact path="/Ride" activeClassName="selected" element={<NavigateRouteProtected><Ride/></NavigateRouteProtected>} />
      </Routes >

      <Routes >
            <Route exact path="/Prius" activeClassName="selected" element={<Prius/>} />
      </Routes >
       
      <Routes >
            <Route exact path="/Taxiinfos" activeClassName="selected" element={<TaxiInfos/>} />
      </Routes >
      <Routes >
            <Route exact path="/Kurierinfos" activeClassName="selected" element={<KurierInfos/>} />
      </Routes >
      <Routes >
            <Route exact path="/Flughafeninfos" activeClassName="selected" element={<FlughafenInfos/>} />
      </Routes >

      <Routes >
            <Route exact path="/Kontakt/Impressum" activeClassName="selected" element={<Impressum/>} />
      </Routes >
      
      </div>
    </div>
  );
}

export default App;
