export const navLinks = [
    { name: "HOME", 
     path: "/" 
    },
    {
      name: "COVID-19",
      path: "/Covid",
    },
    {
      name: "IMPRESSUM",
      path: "/Impressum",
    },
    {
      name: "KONTAKT",
      path: "/Kontakt",
    },

  ];
  
  export const iconLinks = [

    { 
     color_: "#00E676",
     id: true,
     src: "/WhatsAppIcon.png", 
     description: " Taxi per WhatsApp bestellen",
     path: "https://wa.me/41794001111",
    },
    {
      color_: "#009688",
      id: false,
      src: "/SmsIcon.png",
      description: "Taxi per SMS bestellen",
      path: "sms:+41794001111",
    },


    {
      color_: "#1BD924",
      id: true,
      src: "/iphone.png",
      description: "Taxi per Telefon bestellen",
      path: "tel:+41794001111",
    },
    /*
    {
      color_: "#13678A",
      id: false,
      src: "/k12me.png",
      description: "Bestellen Sie jetzt Ihren Tabak bequem online",
      path: "https://k12-kiosk.ch/",
    },
  */
  ];